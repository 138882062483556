<template>
	<div>
		<top-bar title="Configuration" :action="topAction" />
		<div>
			<div class="md:grid md:grid-cols-3 md:gap-6">
				<div class="md:col-span-1 mt-5">
					<div class="px-4 sm:px-0">
						<h3 class="text-lg font-medium leading-6 text-gray-900">{{ item.name }}</h3>
						<p class="mt-1 text-sm leading-5 text-gray-500">
							{{ item.description }}
						</p>
					</div>
				</div>
				<div class="mt-5 md:col-span-2">
					<form @submit.prevent>
						<div class="shadow sm:rounded-md sm:overflow-hidden">
							<div class="px-4 py-5 bg-white sm:p-6">
								<div class="grid grid-cols-3 gap-6">
									<div class="col-span-3 sm:col-span-2">
										<label for="username" class="block text-sm font-medium leading-5 text-gray-700">
											Enable for Model
										</label>
										<div class="mt-1 flex rounded-md">
											<span
												:class="{ 'bg-gray-200': !isAssigned, 'bg-indigo-600': isAssigned }"
												class="relative inline-block flex-no-shrink h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
												role="checkbox"
												tabindex="0"
												:aria-checked="isAssigned.toString()"
												@click="isAssigned = !isAssigned"
												@keydown.space.prevent="isAssigned = !isAssigned"
											>
												<span
													aria-hidden="true"
													:class="{ 'translate-x-5': isAssigned, 'translate-x-0': !isAssigned }"
													class="relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
												>
													<span
														:class="{ 'opacity-0 ease-out duration-100': isAssigned, 'opacity-100 ease-in duration-200': !isAssigned }"
														class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
													>
														<svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
															<path
																d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
																stroke="currentColor"
																stroke-width="2"
																stroke-linecap="round"
																stroke-linejoin="round"
															/>
														</svg>
													</span>
													<span
														:class="{ 'opacity-100 ease-in duration-200': isAssigned, 'opacity-0 ease-out duration-100': !isAssigned }"
														class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
													>
														<svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
															<path
																d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
															/>
														</svg>
													</span>
												</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div v-if="isAssigned" class="hidden sm:block">
			<div class="py-5">
				<div class="border-t border-gray-200"></div>
			</div>
		</div>

		<div v-if="isAssigned" class="mt-10 sm:mt-0">
			<div class="md:grid md:grid-cols-3 md:gap-6">
				<div class="md:col-span-1">
					<div class="px-4 sm:px-0">
						<h3 class="text-lg font-medium leading-6 text-gray-900">{{ operates | capitalize }} selection</h3>
						<p class="mt-1 text-sm leading-5 text-gray-500">
							Choose which will be used in the Model.
						</p>
					</div>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<form @submit.prevent>
						<div class="shadow overflow-hidden sm:rounded-md">
							<div class="px-4 py-5 bg-white sm:p-6">
								<fieldset>
									<legend class="text-base leading-6 font-medium text-gray-900">
										Select the {{ operates | capitalize }} for {{ item.name }}
									</legend>
									<label for="filter_text" class="mt-5 block text-sm font-medium leading-5 text-gray-700">Filter List</label>
									<input
										v-show="modelItems.length > 1"
										id="filter_text"
										v-model="filterValue"
										class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
									<div v-for="option in searchFilter" :key="option.id" class="mt-4">
										<div class="flex items-start">
											<div class="absolute flex items-center h-5">
												<input
													id="comments"
													v-model="option.isChecked"
													type="checkbox"
													class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
												/>
											</div>
											<div class="pl-7 text-sm leading-5">
												<label for="comments" class="font-medium text-gray-700">{{ option.name }}</label>
												<p class="text-gray-500">{{ option.description }}</p>
											</div>
										</div>
									</div>
								</fieldset>
							</div>
							<div v-if="allowSave" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
								<button class="btn btn-indigo" @click="saveConfiguration">
									Save
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { configureMixin } from '@/mixins/configMixins'
import topBar from '@/components/shared/topBar'
export default {
	name: 'ConfigureCreator',
	components: {
		topBar
	},
	mixins: [configureMixin()],
	data: function() {
		return {
			operates: 'fields',
			plural: 'creators'
		}
	},
	computed: {
		topAction() {
			return {
				name: 'Back to Configuration',
				to: { name: 'model-config', params: { id: `${this.model.id}`, name: `${this.model._name}` } }
			}
		}
	}
}
</script>
