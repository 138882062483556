<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'Creators',
	data() {
		return {
			actions: [{ name: 'Creator', route: 'create-creator', icon: 'plus-circle' }]
		}
	},
	metaInfo() {
		return {
			title: 'Creators'
		}
	}
}
</script>
