<template>
	<div class="flex flex-col mt-5">
		<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
			<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
				<table class="min-w-full">
					<thead>
						<tr>
							<th class="thead">
								Name
							</th>
							<th class="thead">
								Used
							</th>
							<th class="thead"></th>
						</tr>
					</thead>

					<tbody class="bg-white">
						<tr v-for="creator in creators" :key="creator.id">
							<td class="px-6 py-4  border-b border-gray-200">
								<div class="text-md leading-5 text-gray-900">{{ creator.name }}</div>
								<div class="text-xs leading-5 text-gray-500">{{ creator.description }}</div>
							</td>
							<td class="td">
								<router-link
									v-for="assignedModel in creator.assignedTo"
									:key="assignedModel.id"
									:to="{
										name: 'model',
										params: { id: assignedModel.id, name: shortName(assignedModel.name) }
									}"
									class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-pink-100 text-pink-800 mr-1"
								>
									{{ assignedModel.name }}
								</router-link>
							</td>
							<td class="td">
								<router-link :to="{ name: 'edit-creator', params: { id: creator.id } }">
									<!-- <font-awesome-icon :icon="['fad', 'edit']" /> -->
									Edit
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { textMixin } from '@/mixins/general'
export default {
	name: 'CreatorsList',
	mixins: [textMixin()],
	props: {
		creators: {
			type: Array,
			default: function() {
				return []
			}
		}
	}
}
</script>
