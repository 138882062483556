import helpers from '@/helpers/utils'

const sorter = function(a, b) {
	if (a._name > b._name) {
		return 1
	}
	if (b._name > a._name) {
		return -1
	}
	return 0
}

export const textMixin = () => ({
	methods: {
		safeName(string) {
			return helpers.shortName(string)
		}
	}
})
export const arrayMixin = () => ({
	methods: {
		sortByName(array) {
			return array.sort(sorter)
		}
	}
})
